<template>
  <div class="stds-dialog pointer-events-auto min-w-[128rem]">
    <full-product-page-detail :source="PRODUCT_PAGES_PAGE_URL" :type="'page'" />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeRouteLeave } from 'vue-router';

import FullProductPageDetail from '@/components/product-page/full-product-page-detail.vue';
import { PRODUCT_PAGES_PAGE_URL } from '@/constants/url.const';
import { useProductPageStore } from '@/stores/product-page.store';
import { showConfirmLeaveDialog } from '@/utils/common.util';

const productPageStore = useProductPageStore();
const { setProductPageDetail } = productPageStore;

onBeforeRouteLeave(async () => {
  setProductPageDetail(undefined);
  return await showConfirmLeaveDialog();
});
</script>
